@import '/src/sass/variables/variables';

.searchBar {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  padding: 10px;
  background: white;
  border-bottom: 2px solid $brand-tertiary;
  z-index: 2;
  input {
    width: 90%;
    border: none;
  }

  i {
    cursor: pointer;
    color: $brand-tertiary;
  }
}
