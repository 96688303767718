/*############ Typo #######################*/
/*#########################################*/

html,
body {
  font-size: 14px;
}

h2.headline {
  font-size: 1.2857rem;
  text-transform: uppercase;
  font-weight: 500;
}

h2 {
  font-size: 1.0714rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 600;
}

h3 {
  font-size: 0.7857rem;
  font-weight: 300;
  margin: 10px 0 0;
}

h4 {
  font-size: 0.7143rem;
  color: $brand-tertiary;
  font-weight: 300;
  margin: 0;
}

hr {
  margin: 30px 0;
}

@media screen and (min-width: $screen-sm-min) {
  html,
  body {
    font-size: 13.37px;
  }

  h2.headline {
    font-size: 1.3463rem;
    margin-top: 0;
  }

  h2 {
    font-size: 1.3313rem;
  }

  h3 {
    font-size: 1.0471rem;
  }

  h4 {
    font-size: 0.7524rem;
  }
}

@media screen and (min-width: $screen-md-min) {
  html,
  body {
    font-size: 16px;
  }

  h2.headline {
    font-size: 1.5625rem;
  }

  h2 {
    font-size: 1.4375rem;
  }

  h3 {
    font-size: 1.0625rem;
  }

  h4 {
    font-size: 0.8125rem;
  }
}
