@import '/src/sass/variables/variables';

.pageHeader {
  cursor: pointer;
  background-color: #0f132f;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
  h3 {
    margin: 0;
    font-weight: 800;
  }
  button {
    padding: 10px;
    margin-left: 20px;
  }
}
.paymentButton {
  padding: 8px 64px;
  margin-left: 5px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
  font-weight: 600;
}

.movieWrapper {
  margin-bottom: 20px;
  background: rgba(14, 18, 42, 0.74);
  padding: 15px;
  figure {
    margin: 0;
  }
  .headerButtons {
    i {
      margin-right: 5px;
      font-size: 24px;
    }
    button {
      color: $brand-primary;
      user-select: none;
      cursor: pointer;
      background: transparent;
      border: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  .movieFigure {
    position: relative;
    overflow: hidden;
  }
  .headline {
    margin-bottom: 20px;
    cursor: pointer;
    margin-top: 10px;
  }
  @media (min-width: 576px) {
    .headline {
      margin-top: 0;
    }
  }

  .remove-movie {
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 5px;
    border: none;
    color: $brand-primary;
    background: transparent;
  }
  .info {
    padding: 10px 0 20px;
    .infoItem {
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }
    .infoLabel {
      display: inline-block;
      font-weight: bold;
    }
  }
  .buttonBox {
    user-select: none;
    cursor: pointer;
    height: 95px;
    background: $brand-primary;
    margin: 8px 0;
    padding: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    .buttonInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      i {
        font-size: 24px;
        margin-bottom: 5px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .pagination {
    font-size: 16px;
  }
  .active {
    a {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      color: white;
    }
  }
  .breakMe {
    a {
      color: white;
    }
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

  .pagination {
  }
}

.hideButton {
  display: none;
}

.showButton {
  display: block;
}
