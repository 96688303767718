@import '/src/sass/variables/variables';

.modalHeader {
  background: #0e122b;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.modalBody {
  button {
    width: 100%;
    border: none;
    padding: 12px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
    font-size: 12px;
  }
  .red {
    background-color: $brand-quaternary;
  }
  input[type='text'] {
    width: 100%;
    padding: 6px;
  }
  h2 {
    font-weight: 800;
  }
  .conent {
    font-size: 0.8em;
    font-weight: 400 !important;
  }
  p {
    font-size: 1.2rem;
  }
  background: #0e122b;
  padding: 20px 30px 50px;
  .bodyLeft {
    font-weight: 800;
  }
}
.modalFooter {
  background: red;
}
.labelOrder {
  font-weight: normal;
  margin-bottom: 5px;
  padding-left: 2em;
  display: inline-block !important;
  position: relative;
  &::before {
    left: 0;
    content: ' ';
    width: 1.3em;
    height: 1.3em;
    background: $brand-primary;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    display: none;
    & + .check:before {
      left: 0;
      content: ' ';
      width: 1.3em;
      height: 1.3em;
      background: $brand-primary;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
    }
    &:checked + .classCheck:before {
      position: absolute;
      left: 0;
      left: -1.5em;
      bottom: 1px;
      content: '\f00c';
      font-family: 'FontAwesome';
      font-size: 1.2em;
      text-align: center;
      color: white;
    }
  }
}
.classCheck {
  position: relative;
  cursor: pointer;
}
.bgDanger {
  background: #f2dede;
  color: black;
  width: 100%;
  padding: 2px;
  i {
    color: $brand-quaternary;
    margin-right: 5px;
    margin-left: 2px;
  }
  p {
    font-weight: 700;
    font-size: 13px;
  }
}

.error {
  h3 {
    color: $brand-quaternary;
    font-weight: 600;
  }
}
