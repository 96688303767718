@import '/src/sass/variables/variables';

.bigButton {
  background: $brand-secondary;
  padding: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
}
.bigButton.trialSelected {
  border: 2px solid white;
}

.checkMark {
  position: absolute;
  top: 87px;
  transform: translateY(-50%);
  left: 0;
  width: 1.5em;
  height: 1.5em;
  font-size: 1.5em;
  text-align: center;
  color: white;
  background: $brand-primary;
  border-radius: 50% 50%;
  margin-left: 5px;
  @media only screen and (max-width: 1200px) and (min-width: 400px) {
    font-size: 1em;
  }
}

.button {
  border-radius: 5px !important;
  padding: 11px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;

  &:disabled {
    opacity: 0.5;
  }
}

.typeWrapper {
  width: 100%;
  padding: 0px 30px;
  cursor: pointer;
  height: 80px;
  background: #d7d7d7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    max-width: 200px;
  }
}
.typeWrapper.selected {
  border: 3px solid #0075ff;
}

.radioButton input {
  transform: scale(1.5);
  margin-right: 50px;
}

.termsAndConditionsCheckbox {
  display: flex;
  justify-content: center;
  a {
    margin-right: 10px;
  }
}
