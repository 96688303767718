.uspContainer {
    text-align: center;
    color: white;
    background: rgba(0, 0, 0, 0.40);
    padding: 0;
}
.uspContainer h2 {
    margin-bottom: 0;
    padding: .5rem;
    text-align: left;
}
.uspPoints {
    list-style-type: none;
    padding: 0;
}

.uspColumnLogo {
    display: flex;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    align-items: flex-start;
}

.uspColumnLogo img {
    padding-top: .5rem;
}

.uspColumnPoints {
    flex-direction: column;
    justify-content: center;
    background-color: #3D4782;
    padding: 25px;
    font-size: 21px;
}
.uspColumnPoints .uspColumnPointsSection {
     margin-left: 0;
     padding-left: 0;
}
.uspColumnPoints li {
    list-style: none;
    padding-top: 1.5rem;
    text-align: left;
    margin-bottom: 0.5rem;
    display: flex;
}
.uspColumnPoints li:nth-child(1) {
    padding-top: .5rem;
}

.uspColumnPoints li i {
    margin-right: 0.5rem;
    margin-top: 4px;
    color: #1abc9c;
    font-size: 1.2rem;
}

/*media query for mobile devices*/
@media only screen and (max-width: 600px) {
    .uspColumnPoints ul:nth-child(1) {
        padding-bottom: 0;
        margin-bottom: 0;
    }

}