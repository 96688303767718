@import '/src/sass/variables/variables';

.navbarWrapper {
  padding: 15px 0 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 15px;
  @media screen and (max-width: 992px) {
    padding: 15px 0 10px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
    border-bottom: none;
  }
  span {
    padding: 0;
  }

  .navbar {
    align-self: flex-end;
    color: #fff;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
    .mainNavigation {
      text-transform: uppercase;
      align-items: center;
      @media screen and (max-width: 992px) {
        //margin-top: 5px;
        //background: rgba(14, 18, 42, 0.74);
        //align-items: flex-start;
      }
      @media screen and (max-width: 767px) {
        align-items: flex-start;
      }

      .navLink {
        color: #fff;
        line-height: 20px;
        text-decoration: none;
        padding: 5px 10px;
        @media screen and (max-width: 767px) {
          padding: 15px 10px;
          display: block;
        }
        @media screen and (max-width: 992px) {
          font-size: 13px;
        }
        @media screen and (min-width: 992px) {
          font-size: 16px;
        }
        @media screen and (min-width: 1200px) {
          font-size: 20px;
        }
        .accountText {
          display: none;
          @media screen and (max-width: 767px) {
            display: block;
            margin-right: 10px;
          }
          @media screen and (max-width: 992px) {
            font-size: 13px;
          }
          @media screen and (min-width: 992px) {
            font-size: 16px;
          }
          @media screen and (min-width: 1200px) {
            font-size: 20px;
          }
        }
      }

      i,
      span {
        font-size: 16px;
        @media screen and (min-width: 1200px) {
          font-size: 20px;
        }
        cursor: pointer;
        user-select: none;
      }

      .linkWrapper {
        @media screen and (max-width: 992px) {
          //width: 100%;
          //border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding: 15px 0;
        }
        @media screen and (max-width: 767px) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          width: 100%;
          padding: 0;
        }
      }

      .signout {
        display: none;

        @media screen and (max-width: 767px) {
          padding: 15px 10px;
          display: flex;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          width: 100%;
          margin: 0;
          font-size: 13px;
        }
      }
    }
  }

  .logoWrapper {
    display: flex;
    color: #fff;
    user-select: none;

    img {
      max-height: 50px;
    }

    @media screen and (min-width: 1200px) {
      img {
        max-height: inherit;
      }
    }

    @media screen and (max-width: 450px) {
      img {
        max-width: 80px;
      }
    }

    @media screen and (max-width: 300px) {
      img {
        max-width: 50px;
      }
    }

    .logoCaption {
      margin-left: 10px;
      color: white;
      @media screen and (min-width: 768px) {
        margin-left: 20px;
      }

      @media screen and (max-width: 450px) {
        font-size: 16px;
      }

      @media screen and (max-width: 300px) {
        font-size: 14px;
      }
    }

    .navbarTitle {
      font-weight: 600;
      font-style: italic;
      font-size: 12px;

      a {
        color: #fff;
        text-decoration: none;
      }

      @media screen and (min-width: 450px) {
        font-size: 16px;
        font-weight: 600;
      }

      @media screen and (min-width: 1200px) {
        font-size: 30px;
        font-weight: 600;
      }
    }
  }

  .searchIcon {
    cursor: pointer;
    padding: 5px 5px;
    width: 24px;
    height: 24px;
    @media screen and (max-width: 767px) {
      display: none;
      font-size: 14px;
    }

    i.fa {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }
  .searchIcon {
    cursor: pointer;
    padding: 5px 5px;

    i.fa {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }

  .loginWrapper {
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    width: 295px;
    padding: 20px;
    background-color: rgba(14, 18, 42, 0.74);
    z-index: 999999999999;
    transition: top 0.5s ease;

    input {
      width: 100%;
      border: none;
      font-size: 1rem;
      padding: 12px 20px;
      margin-bottom: 5px;
      text-align: center;
    }

    .formItem {
      margin-bottom: 10px;
    }

    // @media screen and (max-width: 992px) {
    //   position: relative;
    //   background-color: transparent;
    // }
  }

  .mobileSearchBar {
    display: none;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
    background: white;
    border-bottom: 2px solid $brand-tertiary;
    z-index: 2;
    input {
      width: 90%;
      border: none;
    }

    i {
      cursor: pointer;
      color: $brand-tertiary;
    }

    @media screen and (max-width: 767px) {
      display: flex;
    }
  }

  .iconBar {
    background-color: #fff;
    width: 34px;
    height: 3px;
    display: block;
    margin: 4px 0;
  }

  .vl {
    border-left: 1px solid $brand-tertiary;
    height: 20px;
    margin: 0 4px 0 4px;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}

.accountLogo {
  display: flex !important;
  align-items: center;
}

.dropDown {
  background-color: rgba(14, 18, 42, 0.74);
  color: white;
  cursor: pointer;
  text-decoration: none;
  .dropDownItem {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
}

.dropDownToggle {
  cursor: pointer;
  &::after {
    display: none;
  }
}
