.promotionBadgeWrapper {
  .imgContainer {
    position: relative;
  }

  .imgMargin {
    margin-bottom: 20px;
  }

  .promotionImage {
    //margin-bottom: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .promotionText {
    position: absolute;
    left: 5px;
    text-align: center;
    top: 23px;
    width: 100%;
    font-weight: bold;
    user-select: none;
  }
}
