@import '/src/sass/variables/variables';

.infoWrapper {
  .infoItem {
    font-size: 0.92rem;
    margin-bottom: 3px;
    &.inLine {
      .infoLabel:not(:first-child) {
        margin-left: 10px;
      }
    }

    .infoLabel {
      font-weight: bold;
    }
  }

  margin-bottom: 45px;
}

.contentWrapper {
  .content {
    padding: 20px 0;
  }
  p {
    margin-bottom: 0;
  }
  nav {
    border: none;
    font-size: 20px;

    a {
      background-color: transparent !important;
      color: #fff !important;
      border: none !important;
      border-bottom: 2px solid white !important;
      padding: 0 !important;
    }
  }
}

.loginWrapper {
  padding: 15px 0;
  color: $brand-primary;
  background-color: rgba(14, 18, 42, 0.74);
  form {
    width: 97%;
  }

  h3 {
    color: #fff;
    border: 0;
    padding-left: 15px;
    font-size: 21px;
    font-weight: 500;
    @media screen and (max-width: 480px) {
      padding-right: 15px;
    }
  }

  .formWrapper {
    margin-top: 15px;
    padding-left: 15px;
    @media screen and (max-width: 480px) {
      padding-right: 15px;
    }
    input {
      width: 100%;
      font-size: 1rem;
      padding: 12px 20px;
      border: none;
      color: $brand-secondary;
      text-align: center;
    }
  }
}

.sliderWrapper {
  .thumbWrapper {
    margin-top: 15px;
    display: flex;
  }

  .thumb {
    border: 2px solid #fff;
    cursor: pointer;
    width: 25%;
    margin-right: 3px;
  }
}

.backButton {
  display: flex;
  color: $brand-primary;
  margin: 40px 0 15px;
  text-transform: uppercase;
  vertical-align: center;

  i {
    font-size: 25px;
    margin-right: 5px;
  }
}

.mainWrapper {
  min-height: calc(100vh - 290px);
  padding-bottom: 40px;
}

.menuWrapper {
  margin-top: 10px;

  .menuBox {
    cursor: pointer;
    user-select: none;
    background-color: $brand-primary;
    color: #fff;
    text-align: center;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;
    i {
      font-size: 24px;
    }
    p {
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}
.favButton {
  display: flex;
  margin: 0;
  border: none;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
  background-color: $brand-primary;
  color: #fff;
  margin-left: auto;
  i {
    margin: 0 0px 0 10px;
    justify-self: center;
    font-size: 24px;
  }
}
.marker {
  &.neustart {
    background: url('https://cdn-g.bluemovie.com/old_images/BMO-Neustart-Label.png');
    background-size: cover;
  }

  &.vr {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-VR-Label.png');
    background-size: cover;
  }

  &.blue-movie-plus {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-svod-banner-left-bmo-plus-dark.png');
    background-size: cover;
  }

  background-size: contain;
  position: absolute;
  top: 0;
  padding: 5px;
  width: 100px;
  height: 100px;
  @media screen and (max-width: 767px) {
    width: 75px;
    height: 75px;
  }
}
.modalWrapper {
}

.headline {
  font-family: 'Open Sans Head', Helvetica, Arial, sans-serif;
}
.back {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .prices {
    display: flex;
    flex-direction: row;
  }

  .price {
    padding: 0 5px;
    font-weight: 600;

    .strikedPrice {
      display: inline-block;
      position: relative;
      font-weight: 600;
    }

    .strikedPrice::before {
      content: '';
      position: absolute;
      left: -0.1em;
      right: -0.1em;
      top: 0.38em;
      bottom: 0.38em;
      background: linear-gradient(
        to left top,
        transparent 45.5%,
        currentColor 47.5%,
        currentColor 52.5%,
        transparent 54.5%
      );
      pointer-events: none;
    }
  }
}

.tab {
  background-color: transparent !important;
  border-bottom: 2px solid #fff !important;
  border-radius: 0 !important;
  width: min-content !important;
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
}
