@import '/src/sass/variables/variables';

.sortWrapper {
  margin-bottom: 30px;

  .sortOptions {
    display: block;
    width: 100%;
    background: white;
    color: $brand-secondary;
    border-radius: 0;
    padding: 7px;
    text-transform: uppercase;

    cursor: pointer;
    outline: none;

    select {
      color: $brand-secondary;
      width: 100%;
      border: none;
      font-size: 0.9em;
      font-weight: 600;
    }
  }
  .customSelect {
    border: none;
    border-radius: 0;
    outline: none;
    color: $brand-secondary;
    z-index: 333333333;
  }
}
.movieWrapper {
  background: rgba(14, 18, 42, 0.74);
  padding: 15px;
  margin-bottom: 20px;

  figure {
    margin: 0;
  }

  .headerButtons {
    i {
      margin-right: 5px;
      font-size: 24px;
    }

    color: $brand-primary;
    user-select: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .movieFigure {
    position: relative;
    overflow: hidden;
  }

  .headline {
    margin-bottom: 20px;
    cursor: pointer;
    margin-top: 10px;
  }
  @media (min-width: 576px) {
    .headline {
      margin-top: 0;
    }
  }

  .remove-movie {
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 5px;
    border: none;
    color: $brand-primary;
    background: transparent;
  }

  .info {
    padding: 10px 0 20px;
    margin-right: 10px;
    display: inline-block;
    .infoItem {
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }

    .infoLabel {
      display: inline-block;
      font-weight: bold;
    }
  }

  .buttonBox {
    user-select: none;
    cursor: pointer;
    height: 95px;
    background: $brand-primary;
    margin: 8px 0;
    padding: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    .buttonInner {
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        font-size: 24px;
        margin-bottom: 5px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.infoWrapper {
  //
}
