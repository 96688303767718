@import '/src/sass/variables/variables';

.sortWrapper {
  margin-bottom: 30px;
  z-index: 999;
  .sortOptions {
    display: block;
    width: 100%;
    background: white;
    color: $brand-secondary;
    border-radius: 0;
    padding: 4px;
    text-transform: uppercase;

    cursor: pointer;
    outline: none;

    select {
      color: $brand-secondary;
      width: 100%;
      border: none;
      font-size: 0.9em;
      font-weight: 600;
    }
  }
  .customSelect {
    border: none;
    border-radius: 0;
    outline: none;
    color: $brand-secondary;
    z-index: 333333333;
  }
}
.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .active {
    a {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      color: white;
      font-size: 16px;
    }
  }
  .breakMe {
    a {
      color: white;
    }
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

  .pagination {
    font-size: 16px;
  }
}
