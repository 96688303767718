@import '/src/sass/variables/variables';

.footer {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 30px 0 0;
  max-width: 1160px;
  margin: 0 auto;
  background: rgba(14, 18, 42, 0.74);
  font-size: 0.87rem;
  height: 180px;
  @media screen and (max-width: 767px) {
    height: auto;
  }

  .ul {
    list-style: none;
    display: inline-flex;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
    }

    li {
      border-left: 1px solid #2a325e;

      @media screen and (max-width: 767px) {
        border-left: none;
        margin-bottom: 10px;
        padding-bottom: 10px;
        width: 100%;
        border: none;
      }

      &:first-of-type {
        border-left: none;
      }

      a {
        color: #fff;
        padding: 0 15px;

        @media screen and (max-width: 768px){
          font-size: 14px;
        }
      }
    }
  }

  .statement {
    display: inline-flex;
    color: #fff;
  }

  .buttonTop {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      background: $brand-primary;
      margin: 8px 0;
      padding: 10px 15px;
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      font-size: 18px;
      color: #fff;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
    }
  }
}
