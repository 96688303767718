.headerImage {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  margin-bottom: 15px;
}
