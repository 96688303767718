@import '/src/sass/variables/variables';

.infoBox {
  user-select: none;
  background-color: $brand-primary;
  color: #fff;
  text-align: center;
  padding: 15px;
  img {
    max-height: 115px;
    margin: 15px 0;
  }
  p {
    height: 88px;
  }
}

@media (min-width: 576px) {
  .infoBox {
    height: 400px;
    img {
      height: 100px;
    }
  }
}
@media (min-width: 768px) {
  .infoBox {
    height: 350px;
    img {
      height: 115px;
    }
  }
}
@media (min-width: 992px) {
  .infoBox {
    height: 360px;
  }
}
@media (min-width: 1200px) {
  .infoBox {
    height: 350px;
  }
}
