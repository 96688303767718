.container {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }

  .content {
    background: #0f132f;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 15px;
    justify-items: center;
    padding: 10px;
    border-radius: 4px;

    p {
      text-align: center;
      margin: 0;
    }

    .status {
      background: #1a8754;
      width: fit-content;
      padding: 3px 12px;
      border-radius: 4px;
      font-size: 14px;
      height: fit-content;
    }

    .buttons {
      grid-column: 1 / -1; /* Span all columns */
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 10px;

      button {
        display: flex;
        margin: 0;
        border: none;
        padding: 10px;
        text-transform: uppercase;
        background-color: #3e92d4;
        color: #fff;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
      }

      .editPaymentMethodButton {
        background: #3e92d4;
        border: none;
        padding: 10px 28px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: white;
        font-size: 14px;
      }

      .cancelSubscriptionButton {
        background: #b51511;
        border: none;
        padding: 10px 28px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
}
