.loginWrapper {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  width: 295px;
  padding: 20px;
  background-color: rgba(14, 18, 42, 0.74);
  z-index: 999999999999;
  transition: top 0.5s ease;

  input {
    width: 100%;
    border: none;
    font-size: 1rem;
    padding: 12px 20px;
    margin-bottom: 5px;
    text-align: center;
  }
}

.formItem {
  margin-bottom: 10px;
}
