@import '/src/sass/variables/variables';

.wrapper {
  background-image: url(https://cdn-g.bluemovie.com/old_images/BMO_LoginTeaser.jpg);
  background-size: cover;
  background-position: right;
  height: auto;
  min-height: 430px;
  .content {
    margin-top: 2%;
    margin-left: 4%;
    margin-bottom: 40px;
    background-color: rgba(14, 18, 42, 0.74);
    padding: 15px;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
  }
  .button {
    background: $brand-primary;
    margin: 8px 0;
    padding: 10px;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-decoration: none;
  }
}
