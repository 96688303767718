@import '/src/sass/variables/variables';

.modalHeader {
  background: #0e122b;
  text-align: center;

  font {
    font-size: 24px;
    font-weight: 600;
  }

  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.modalBody {
  background: #0e122b;
  padding: 20px 30px 40px;
  button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
  }
  input {
    width: 100%;
    padding: 6px;
  }
  h2 {
    font-weight: 800;
    font-size: 23px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 40px;
  }
}
