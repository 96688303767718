.termsAndConditionsCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;

  .termsAndConditionsText {
    margin-left: 10px;
  }

  a {
    margin: 0 5px;
  }

  input {
    transform: scale(1.5);
    accent-color: #3e92d4;
  }
}
