@import '/src/sass/variables/variables';

.bottomLink {
  text-align: center;
  margin-bottom: 30px;
  a {
    background: $brand-primary;
    margin: 8px 0;
    padding: 10px;
    display: inline-block;
  }
}
