.container {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.tableNoSideBorders {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-top: 1px solid grey;
  border-bottom: none;

  .status {
    background: #1a8754;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
  }

  thead th {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  tbody tr:first-child {
    border-top: 1px solid #dee2e6;
  }

  tbody {
    border-bottom: none;
  }

  th,
  td {
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 10px 0;
  }
}

.yourTicket {
  width: 30%;
}
.nextCharge {
  width: 100%;
}
.monthlyTicket {
  width: 30%;
}
.bodyDate {
  width: 20%;
}
.bodyCancel {
  width: 20%;
}

.downloadMandat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 20px;

  p {
    margin: 0;
  }

  button {
    display: flex;
    margin: 0;
    border: none;
    padding: 10px;
    text-transform: uppercase;
    background-color: #3e92d4;
    color: #fff;
  }
}

.editPaymentMethodButton {
  background: #3e92d4;
  border: none;
  padding: 10px 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
}

.cancelSubscriptionButton {
  background: #b51511;
  border: none;
  padding: 10px 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 10px;
}
