@import '/src/sass/variables/variables';

.mobileSearchBar {
  display: none;
  justify-content: space-around;
  width: 100%;
  padding: 10px;
  background: white;
  border-bottom: 2px solid $brand-tertiary;
  z-index: 2;
  input {
    width: 90%;
    border: none;
  }

  i {
    cursor: pointer;
    color: $brand-tertiary;
  }

  @media screen and (max-width: 767px) {
    display: flex;
  }
}
