@import '/src/sass/variables/variables';

.loginWrapper {
  padding: 15px 0;
  color: $brand-primary;
  background-color: rgba(14, 18, 42, 0.74);
  form {
    width: 97%;
  }

  h3 {
    color: #fff;
    border: 0;
    padding-left: 15px;
    font-size: 21px;
    font-weight: 500;
    @media screen and (max-width: 480px) {
      padding-right: 15px;
    }
  }

  .formWrapper {
    margin-top: 15px;
    padding-left: 15px;
    @media screen and (max-width: 480px) {
      padding-right: 15px;
    }
    input {
      width: 100%;
      font-size: 1rem;
      padding: 12px 20px;
      border: none;
      color: $brand-secondary;
      text-align: center;
    }
  }
}
.headerImage {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  margin-bottom: 15px;
}
.movieWrapper {
  margin-bottom: 15px;
  .movieImage {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  .movieText {
    text-overflow: ellipsis;
    a {
      color: #fff;
      display: block;
      text-transform: capitalize;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.info {
  margin-bottom: 30px;
}
.infoBox {
  user-select: none;
  background-color: $brand-primary;
  color: #fff;
  text-align: center;
  padding: 15px;
  img {
    max-height: 115px;
    margin: 15px 0;
  }
  p {
    height: 88px;
  }
}
.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .pagination {
    font-size: 16px;
  }
  .active {
    a {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      color: white;
    }
  }
  .breakMe {
    a {
      color: white;
    }
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

  .pagination {
  }
}
.typeWrapper {
  width: 100%;
  padding: 0px 30px;
  cursor: pointer;
  height: 80px;
  background: #d7d7d7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    max-width: 200px;
  }
}
.typeWrapper.selected {
  border: 3px solid #0075ff;
}
.radioButton input {
  transform: scale(1.5);
  margin-right: 50px;
}
.ticketWrapper {
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.modalWrapper {
  width: 600px;
  background-color: black;
}

.modalHeader {
  border-bottom: 1px solid #dee2e6;
  color: white;
  background-color: #0e122b;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  .modalTitle {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    h2 {
      margin: 0;
      padding: 0;
    }
  }
}

.modalBody {
  align-items: center;
  .button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
  }
  p {
    font-weight: 600;
  }
  background: #0e122b;
  padding-bottom: 50px;
}

.selected {
  border: 2px solid white;
}
.bigButton {
  background: $brand-secondary;
  padding: 8px;
  cursor: pointer;
  border: 2px solid transparent;
}
.bigButton.trialSelected {
  border: 2px solid white;
}
.checkMark {
  position: absolute;
  top: 87px;
  transform: translateY(-50%);
  left: 0;
  width: 1.5em;
  height: 1.5em;
  font-size: 1.5em;
  text-align: center;
  color: white;
  background: $brand-primary;
  border-radius: 50% 50%;
  margin-left: 5px;
  @media only screen and (max-width: 1200px) and (min-width: 400px) {
    font-size: 1em;
  }
}

.button {
  border-radius: 5px !important;
  padding: 11px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
}
.price {
  font-weight: 700;
  color: $brand-primary;
  font-size: 2rem;
}
.sepa {
  height: 27px;
  max-width: unset;
}

@media (min-width: 576px) {
  .infoBox {
    height: 400px;
    img {
      height: 100px;
    }
  }
}
@media (min-width: 768px) {
  .infoBox {
    height: 350px;
    img {
      height: 115px;
    }
  }
}
@media (min-width: 992px) {
  .infoBox {
    height: 360px;
  }
}
@media (min-width: 1200px) {
  .infoBox {
    height: 350px;
  }
}
.hideButton {
  display: none;
}

.showButton {
  display: block;
}

.errors{
  color: red;
  margin: 1rem 0;
}