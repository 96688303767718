.container {
  max-width: 1170px;
  margin: auto;
  padding: 0 5px;
}

.priceContainer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 40px 0;

  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}
.imgResponsive {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
}
.checkList {
  padding: 0;
  li {
    position: relative;
    padding: 5px 0 7px 0;
  }
}
.ticketWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 20px;

  .ticketColumnWrapper {
    padding: 0;
    width: 32%;
    cursor: pointer;

    @media screen and (max-width: 576px) {
      width: 80%;
    }
  }

  .ticket {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
}

.bottom {
  text-align: center;
}
