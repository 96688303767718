@import '~bootstrap/scss/bootstrap';
@import './sass/mixins/linear_gradient';
@import './sass/variables/_font.scss';
@import './sass/variables/_screen.scss';
@import './sass/variables/_variables';
@import './sass/variables/_typo.scss';

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/opensans-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/opensans-light-webfont.woff2') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('./assets/fonts/opensans-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Semibold';
  src: url('./assets/fonts/opensans-semibold-webfont.woff2') format('woff2'),
    url('./assets/fonts/opensans-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  //-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include linear-gradient(
    to right,
    $brand-secondary 0%,
    lighten($brand-secondary, 15%) 30%,
    lighten($brand-secondary, 20%) 50%,
    lighten($brand-secondary, 15%) 70%,
    $brand-secondary 100%
  );
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #ffffff;
  line-height: 1.42857143;
}

table {
  color: #ffffff;
}

a {
  color: $brand-primary;

  &:hover {
    color: $link-hover-color;
  }
  &:visited,
  &:focus {
    outline: none;
  }
}
body,
html {
  height: 100%;
}
.navbar-light .navbar-toggler {
  background: #0f132f;
  padding: 0 4px;
  width: 44px;
  height: 37px;
}
.navbar-light .navbar-toggler button:active,
button:focus {
  border: none;
  outline: 0;
}
@media screen and (min-width: 1200px) {
  .container {
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar {
    align-items: flex-end;
  }
}
@media screen and (max-width: 480px) {
  .header .navbar-nav {
    background: rgba(14, 18, 42, 0.74);
  }
}

.dropdown-item.active {
  background-color: transparent;
}
@media screen and (min-width: 767px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 98%;
  }
}
@media screen and (min-width: 992px) {
  .container,
  .container-md,
  .container-xl {
    max-width: 960px !important;
  }
}
@media screen and (min-width: 1200px) {
  .container,
  .container-md,
  .container-xl {
    max-width: 1170px !important;
  }
}

.dropdown-item:hover {
  background-color: transparent;
  text-decoration: none;
}
input[name='password'] {
  -webkit-text-security: disc;
}

.vjs-menu-button-popup {
  .vjs-menu {
    .vjs-menu-content {
      max-height: unset !important;
    }
  }
}

#myCarousel .list-inline {
  white-space: nowrap;
  overflow-x: auto;
}

#myCarousel .carousel-indicators {
  position: static;
  left: initial;
  width: initial;
  margin-left: initial;
}

#myCarousel .carousel-indicators > li {
  width: initial;
  height: initial;
  text-indent: initial;
}

#myCarousel .carousel-indicators > li.active img {
  opacity: 0.7;
}
.dropdown-container {
  border-radius: 0 !important;
  font-family: $font-family-base !important;
  color: black !important;
  font-weight: 500 !important;
  .select-item {
    padding: 3px !important;
    margin-bottom: 2px !important;
  }
  .dropdown-heading-value {
    span {
      color: black;
    }
  }
}
.paymentButton.active {
  border: 2px solid rgb(207, 83, 80);
}
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
@media screen and (max-width: 767px) {
  .swiper-container-android,
  .swiper-container-ios {
    position: initial !important;
  }
}
.modal-content {
  background: none;
  border: none;
}
.modal-dialog {
  max-width: 600px;
}
@media screen and (max-width: 580px) {
  .modal-content .col {
    flex-basis: initial;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .col-sm-hidden,
  .col-hidden {
    display: none !important;
  }
  .swiper-container {
    position: initial;
  }
}

// @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
//   .col-sm-hidden, .col-hidden{
//     display: none !important
//   }
//   .col-sm-12{
//     flex: 0 0 100%;
//     max-width: 100%;
//   }
// }
@media (max-width: 991px) and (min-width: 768px) {
  .col-sm-hidden,
  .col-hidden {
    display: none !important;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    position: relative !important;
  }
}

.app {
  min-height: 100vh;
  flex: 1 0 auto;
}

.result-info li {
  position: relative;
  list-style: none;
  padding-bottom: 20px;
}
.result-info li::before {
  position: absolute;
  top: -5px;
  left: -30px;
  color: #4e5991;
  content: '\f058';
  font-family: 'FontAwesome';
  font-size: 1.4em;
}
.cookieButton {
  &:hover {
    color: black !important;
  }
}
.cookieContent {
  a {
    color: black;
    text-decoration: underline;
  }
}

//MAINTENANCE

.content-maintenance {
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  align-items: center;
}
.container-maintenance {
  width: 1200px;
  height: auto;
  margin: 2% auto;
  padding: 1% 3%;
  border: 1px solid white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: white;
  h1 {
    font-family: 'Open Sans', Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-bottom: 0;
    font-size: 2.6em;
    line-height: 1em;
  }
  h2 {
    font-family: 'Open Sans', Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  p {
    font-family: 'Open Sans', Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1.6em;
  }
}
.maintenance-img {
}
.maintenanceIcon {
  width: 6%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-maintenance {
    width: 90%;
  }
  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1em;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-maintenance {
    width: 90%;
  }
  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .container-maintenance {
    width: 90%;
  }
  .maintenanceIcon {
    width: 10%;
  }
  h1 {
    font-size: 1.6em;
  }
  h2 {
    font-size: 1.2em;
  }
  p {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  .container {
    width: 90%;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
  }
  p {
    font-size: 1.2em;
  }
}
details summary::-webkit-details-marker {
  display: none;
}

.ant-modal-content {
  padding: 0 !important;
  height: 100%;
  width: 100%;
}

.stb_left_column {
  h1 {
    color: #ffffff;
  }
  h3 {
    font-size: 1.75rem;
  }
}

.card-body {
  @media screen and (max-width: 450px) {
    padding: 0;
  }
}

.accordion-body {
  @media screen and (max-width: 450px) {
    padding: 0;
  }
}
