@import '/src/sass/variables/variables';

.secondarySlider {
  background: rgba(0, 0, 0, 0.45);
  padding: 1.5em;
  margin: 15px 0;
  .headline {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    .title {
      color: #fff;
      cursor: pointer;
      h2 {
        font-weight: bold;
      }
    }
    .link {
      color: $brand-primary;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
.movieDetails {
  margin-bottom: 15px;
  text-align: left;
  img {
    max-width: 100%;
    max-height: 250px;
  }
}
.movieLink {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .movieTitle {
    color: #fff;
    font-weight: 300;
    @media screen and (max-width: 767px) {
      font-size: 0.7857rem;
    }
  }
}

.swiper {
  position: relative;
}
.prevButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  font-size: 3.6em;
  position: absolute;
  top: 50%;
  width: 27px;
  margin-top: -17px;
  z-index: 10;
  cursor: pointer;
  left: -1px;
  padding: 0;
  transform: translateY(-50%);
  color: #fff;
  background-color: rgba(37, 49, 103, 0.8);
  @media screen and (max-width: 1200px) {
    margin-top: -15px;
  }
  @media screen and (max-width: 992px) {
    margin-top: -15px;
  }

  @media screen and (max-width: 768px) {
    background: none;
    font-size: 38px;
    left: -25px;
    opacity: 0.4;
  }
}
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 3.6em;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  width: 27px;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
  right: -1px;
  padding: 0;
  color: #fff;
  background-color: rgba(37, 49, 103, 0.8);
  @media screen and (max-width: 1200px) {
    margin-top: -15px;
  }
  @media screen and (max-width: 992px) {
    margin-top: -15px;
  }
  @media screen and (max-width: 768px) {
    background: none;
    font-size: 38px;
    right: -25px;
    opacity: 0.4;
  }
}
.marker {
  cursor: pointer;
  &.neustart {
    background: url('https://cdn-g.bluemovie.com/old_images/BMO-Neustart-Label.png');
    background-size: cover;
  }

  &.vr {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-VR-Label.png');
    background-size: cover;
  }

  &.blue-movie-plus {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-svod-banner-left-bmo-plus-dark.png');
    background-size: cover;
  }

  background-size: contain;
  z-index: 10;
  position: absolute;
  top: 0;
  padding: 5px;
  width: 100px;
  height: 100px;
  @media screen and (max-width: 767px) {
    width: 75px;
    height: 75px;
  }
}
.teaser {
  @media screen and (max-width: 992px) {
    display: none;
  }
  .teaserLink {
    color: white;
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
}
