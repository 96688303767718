@import '/src/sass/variables/variables';

.ticketWrapper {
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.checkMark {
  position: absolute;
  top: 87px;
  transform: translateY(-50%);
  left: 0;
  width: 1.5em;
  height: 1.5em;
  font-size: 1.5em;
  text-align: center;
  color: white;
  background: $brand-primary;
  border-radius: 50% 50%;
  margin-left: 5px;
  @media only screen and (max-width: 1200px) and (min-width: 400px) {
    font-size: 1em;
  }
}

.selected {
  border: 2px solid white;
}

.price {
  font-weight: 700;
  color: $brand-primary;
  font-size: 2rem;
}
