.menuWrapper {
  z-index: 999999;
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  padding: 15px 0 10px 0;
  -webkit-box-shadow: 6px 11px 6px 2px rgba(0, 0, 0, 0.78);
  box-shadow: 6px 11px 6px 2px rgba(0, 0, 0, 0.78);
  background-image: linear-gradient(to right, #1f2e64, #1f2b5e, #1e2757, #1d2451, #1c214b);
  display: flex;
  flex-direction: column;
}
.menuItem {
  color: white;
  padding: 10px 40px 10px 40px;
  text-decoration: none;
  margin-bottom: 3px;
  cursor: pointer;
  user-select: none;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-image: linear-gradient(to right top, #3e92d4, #419bdd, #45a5e5, #49aeee, #4db8f6);
  }
}

.signout {
  display: flex;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    display: none;

  }
}
