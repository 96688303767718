.container {
  width: 288px;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 16px;
  background: #0f132f;
  border-radius: 4px;
  cursor: pointer;

  .visaMaser{
    display: flex;
    gap: 12.5px;
  }
}
