.overlay {
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  z-index: 9823409423089423809423809234;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
}
.player {
  width: 70%;
  margin-top: 30px;
  text-align: right;
  i {
    font-size: 25px;
    color: #ffffff;
    display: inline-block;
    padding: 3px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}
