@import '/src/sass/variables/variables';

.modalHeader {
  background: #0e122b;
  padding: 20px;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.modalBody {
  padding-top: 30px;
  button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    background-color: $brand-primary;
    color: #fff;
  }
  input {
    width: 100%;
    padding: 6px;
  }
  h2 {
    font-weight: 800;
  }
  p {
    font-weight: 600;
  }
  background: #0e122b;
  padding-bottom: 30px;
}
.modalFooter {
  background: red;
}
.conent {
  font-size: 0.8em;
  font-weight: 400 !important;
}
