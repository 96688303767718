@import '/src/sass/variables/variables';

.button {
  border-radius: 5px !important;
  padding: 11px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
  margin-top: 30px;
}
