//Colors
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$brand-primary: #3e92d4;
$brand-secondary: #0e122a;
$brand-tertiary: #3047af;
$brand-quaternary: #ad140f;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$brand-secondary-transparent: rgba(14, 18, 42, 0.4);

//** Background color for `<body>`.
$body-bg: $brand-secondary;
//** Global text color on `<body>`.
$text-color: #fff !default;

//** Global textual link color.
$link-color: $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;
