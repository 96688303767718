.typeWrapper {
  width: 100%;
  padding: 0px 30px;
  cursor: pointer;
  height: 80px;
  background: #d7d7d7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    max-width: 200px;
  }
}
.typeWrapper.selected {
  border: 3px solid #0075ff;
}
.radioButton input {
  transform: scale(1.5);
  margin-right: 50px;
}
