.headerImage {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  margin-bottom: 15px;
}
.movieWrapper {
  margin-bottom: 15px;
  .movieImage {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  .movieText {
    text-overflow: ellipsis;
    a {
      color: #fff;
      display: block;
      text-transform: capitalize;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
