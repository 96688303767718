@import '/src/sass/variables/variables';

.modalHeader {
  background: #0e122b;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.modalBody {
  padding: 48px;

  button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
    border-radius: 4px;
  }
  input {
    width: 100%;
    padding: 6px;
  }
  h2 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 20px;
  }
  p {
    font-weight: 400;
    margin-bottom: 32px;
    font-size: 14px;
  }
  background: #0e122b;
  padding-bottom: 50px;
}
