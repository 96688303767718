@import '/src/sass/variables/variables';

.secondarySlider {
  background: rgba(0, 0, 0, 0.45);
  padding: 1.5em;
  margin: 15px 0;
  position: relative;

  .headline {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    .title {
      cursor: pointer;
      color: #fff;
      h2 {
        font-weight: bold;
      }
    }
    .link {
      color: $brand-primary;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
.swiper {
  position: relative;
}
.movieDetails {
  text-align: left;
  img {
    max-width: 100%;
    @media screen and (max-width: 480px) {
      // max-width: 146px;
    }
    // max-height: 250px;
  }
}
.movieWrapper {
  margin-bottom: 15px;

  .movieImage {
    position: relative;
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .movieText {
    text-overflow: ellipsis;

    a {
      color: #fff;
      display: block;
      text-transform: capitalize;
      font-weight: 300;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .promotionImage {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .promotionText {
    position: absolute;
    left: 5px;
    text-align: center;
    top: 23px;
    width: 100%;
    font-weight: bold;
    user-select: none;
  }
}

.movieLink {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  .movieTitle {
    color: #fff;
    font-weight: 300;
    @media screen and (max-width: 767px) {
      font-size: 0.7857rem;
    }
  }
}
.prevButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 91%;
  font-size: 3.6em;
  position: absolute;
  top: 50%;
  width: 27px;
  margin-top: -17px;
  z-index: 10;
  cursor: pointer;
  left: -1px;
  padding: 0;
  transform: translateY(-50%);
  color: #fff;
  background-color: rgba(37, 49, 103, 0.8);
  @media screen and (max-width: 1200px) {
    top: 0;
    bottom: 28px;
    height: initial;
    margin: 0;
    transform: unset;
  }
  @media screen and (max-width: 992px) {
    bottom: 25px;
    height: initial;
    margin: 0;
    transform: unset;
  }
  @media screen and (max-width: 768px) {
    background: none;
    font-size: 38px;
    left: -4px;
    opacity: 0.4;
  }
  @media screen and (max-width: 480px) {
    background: none;
    font-size: 38px;
    left: -4px;
    opacity: 0.4;
  }
}
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91%;
  font-size: 3.6em;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  width: 27px;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
  right: -1px;
  padding: 0;
  color: #fff;
  background-color: rgba(37, 49, 103, 0.8);
  @media screen and (max-width: 1200px) {
    top: 0;
    bottom: 28px;
    height: initial;
    margin: 0;
    transform: unset;
  }
  @media screen and (max-width: 992px) {
    bottom: 25px;
    height: initial;
    margin: 0;
    transform: unset;
  }
  @media screen and (max-width: 768px) {
    background: none;
    font-size: 38px;
    right: -4px;
    opacity: 0.4;
  }
  @media screen and (max-width: 480px) {
    background: none;
    font-size: 38px;
    right: -4px;
    opacity: 0.4;
  }
}
.marker {
  &.neustart {
    background: url('https://cdn-g.bluemovie.com/old_images/BMO-Neustart-Label.png');
    background-size: cover;
    cursor: pointer;
  }

  &.vr {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-VR-Label.png');
    background-size: cover;
  }

  &.blue-movie-plus {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-svod-banner-left-bmo-plus-dark.png');
    background-size: cover;
  }

  background-size: contain;
  z-index: 10;
  position: absolute;
  top: 0;
  padding: 5px;
  width: 100px;
  height: 100px;
  @media screen and (max-width: 767px) {
    width: 75px;
    height: 75px;
  }
}
.teaserLink {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
