@import '/src/sass/variables/variables';

textarea {
  padding: 6px !important;
}

.modalHeader {
  background: #0e122b;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.title {
  color: #fff;
  font-size: 1.5rem;
}

.modalBody {
  button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
  }
  input {
    width: 100%;
    padding: 6px;
  }
  h2 {
    font-weight: 800;
  }
  p {
    font-weight: 600;
  }
  background: #0e122b;
  padding-bottom: 50px;
}
