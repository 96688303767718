@import '/src/sass/variables/variables';

.modalHeader {
  border-bottom: 1px solid #dee2e6;
  color: white;
  background-color: #0e122b;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  .modalTitle {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    h2 {
      margin: 0;
      padding: 0;
    }
  }
}

.modalBody {
  align-items: center;
  .button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
  }
  p {
    font-weight: 600;
  }
  background: #0e122b;
  padding-bottom: 50px;
}
.button {
  border-radius: 5px !important;
  padding: 11px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
}
