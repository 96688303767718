@import '/src/sass/variables/variables';

.row td {
  padding: 14px;
  text-align: center;
}
.button:hover {
  cursor: pointer;
  user-select: none;
}
.listContainer {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.listItem {
  color: black;
  padding: 10px;
  width: 500px;
  @media screen and (max-width: 540px) {
    max-width: 90%;
  }
  //background: #D7D7D7;
  border-radius: 15px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  i {
    color: #242f6d;
    //font-size: 60px;
  }
}
.content {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  width: 100%;
  p {
    margin: 0;
  }
}
.deleteWrapper {
  flex: 1;
  text-align: end;
  i {
    font-size: 25px;
    color: red;
  }
}

.modalHeader {
  background: #0e122b;
  button {
    color: white;
    &:hover {
      color: white;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.modalBody {
  button {
    width: 100%;
    border: none;
    padding: 8px 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: $brand-primary;
    color: #fff;
  }
  h2 {
    font-weight: 800;
  }
  p {
    font-weight: 600;
  }
  background: #0e122b;
  padding-bottom: 50px;
}
.modalFooter {
  background: red;
}

details > summary {
  list-style-type: none;
  font-size: 25px;
  i {
    transition: 150ms;
  }
}
details[open] > summary {
  list-style-type: none;
  i {
    transform: rotate(90deg);
  }
}

details[open] > .singleDevice:not(:last-child) {
  margin-bottom: 5px;
}

.singleDevice {
  background: #d7d7d7;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
  padding: 15px;
  border-radius: 5px;
  i {
    font-size: 25px;
    color: #0c5392;
  }
}
.summary {
  background: #d7d7d7;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.deviceInfo {
  display: flex;
  .lastActivity {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p.device {
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}
.iconWrapper {
  i {
    color: white;
    margin: 0;
    font-size: 30px;
    padding: 0;
  }
  margin-right: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #0c5392;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
