@import '/src/sass/variables/variables';

.menuBox {
  cursor: pointer;
  user-select: none;
  background-color: $brand-primary;
  color: #fff;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: 6px 0;
  i {
    font-size: 24px;
  }
  p {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    // max-width: 100px;
    margin: 0 auto;
    @media screen and (max-width: 580px) {
      max-width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 11px;
    }
  }
}
.active {
  background-color: #9a3011;
}
.bg-danger {
  background-color: #962e10 !important;
}
.mainText {
  display: flex;
  flex-direction: row;
}
