@import '/src/sass/variables/variables';

.pageHeader {
  cursor: pointer;
  background-color: #0f132f;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
  h3 {
    margin: 0;
    font-weight: 800;
  }
  button {
    padding: 10px;
    margin-left: 20px;
  }
}
.card {
  cursor: pointer;
  background-color: #0f132f;
  margin-bottom: 5px;
  font-weight: 800;
  input {
    width: 100%;
  }
  .cardBody {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff !important;

    span {
      font-weight: 800;
      color: #fff;
    }
  }
}
.paymentButton {
  padding: 8px 64px;
  margin-left: 5px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
  font-weight: 600;
}
.paymentInput {
  margin-left: 5px;
  min-height: 36px;
  padding: 5px;
  border: none;
  color: $brand-secondary;
}
.paymentInputWrapper {
  margin-bottom: 18px;
}

label {
  color: #fff;
  font-weight: 800;
}
