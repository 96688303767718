@import '/src/sass/variables/variables';

.cardImage {
  max-height: 48px;
  max-width: 160px;
}
.pageHeader {
  cursor: pointer;
  background-color: #0f132f;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
  h3 {
    margin: 0;
    font-weight: 800;
  }
  button {
    padding: 10px;
    margin-left: 20px;
  }
}
.imageGroup {
  img {
    max-height: 32px;
    max-width: 112px;
  }
}
.card,
.accordion_button,
.accordion_item {
  cursor: pointer;
  background-color: #0f132f !important;
  margin-bottom: 5px;
  font-weight: 800;
  border: none;
  color: #fff !important;
  box-shadow: none !important;
  .cardBody {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 800;
    }
  }
}

.accordion_button {
  height: 48px;
  padding: 12px 20px;
}
.accordion_button::after {
  display: none !important;
}

.table {
  color: white;
  border: #dee2e6;
  th {
    color: white;
  }
}
.paymentButton {
  padding: 8px 64px;
  margin-left: 5px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
  font-weight: 600;
}
.paymentInput {
  margin-left: 5px;
  min-height: 36px;
  padding: 5px;
  border: none;
  color: $brand-secondary;
}
