@import '/src/sass/variables/variables';

.pageHeader {
  cursor: pointer;
  background-color: #0f132f;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;

  h3 {
    margin: 0;
    font-weight: 800;
  }

  button {
    padding: 10px;
    margin-left: 20px;
  }
}

.paymentButton {
  padding: 8px 64px;
  margin-left: 5px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
  font-weight: 600;
}

.go2646822163 {
  height: 42px;
}

.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .pagination {
    font-size: 16px;
  }
  .active {
    a {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      color: white;
    }
  }
  .breakMe {
    a {
      color: white;
    }
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

  .pagination {
  }
}
