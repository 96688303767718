.wrapper {
  background-image: url(https://cdn-g.bluemovie.com/old_images/BMO_LoginTeaser.jpg);
  background-size: cover;
  background-position: right;
  height: auto;
  min-height: 430px;
  .content {
    margin-top: 2%;
    margin-left: 4%;
    margin-bottom: 40px;
    background-color: rgba(14, 18, 42, 0.74);
    padding: 15px;
  }
  .formWrapper {
    input {
      width: 100%;
      border: none;
      font-size: 1rem;
      padding: 12px 20px;
      margin-bottom: 5px;
      text-align: center;
    }
    .formItem {
      align-items: center;
      p {
        text-align: right;
      }
    }
  }
}
.textWrapper {
  margin-top: 15px;
  margin-bottom: 30px;
}
.loginButton {
  width: 100px;
  display: flex;
  align-items: center;
}
