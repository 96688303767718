@import '/src/sass/variables/variables';

.movieWrapper {
  margin-bottom: 15px;
  .movieImage {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  .movieText {
    text-overflow: ellipsis;
    a {
      color: #fff;
      display: block;
      text-transform: capitalize;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .pagination {
    font-size: 16px;
  }
  .active {
    a {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      color: white;
    }
  }
  .breakMe {
    a {
      color: white;
    }
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

  .pagination {
  }
}

.hideButton {
  display: none;
}

.showButton {
  display: block;
}
