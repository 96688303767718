.mainSlider {
  margin-bottom: 15px;
  position: relative;

  &.cursorAuto {
    cursor: auto !important;
  }

  &.cursorPointer {
    cursor: pointer !important;
  }
}

.prevButton {
  font-size: 64px;
  position: absolute;
  top: 50%;
  width: 27px;
  z-index: 10;
  cursor: pointer;
  left: 0;
  padding: 0;
  transform: translateY(-50%);
  color: #fff;
  background-color: rgba(37, 49, 103, 0.8);
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.nextButton {
  font-size: 64px;
  position: absolute;
  top: 50%;
  width: 27px;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
  right: 0;
  padding: 0;
  color: #fff;
  background-color: rgba(37, 49, 103, 0.8);
  @media screen and (max-width: 576px) {
    display: none;
  }
}
img {
  max-width: 100%;
  height: auto;
}
