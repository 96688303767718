.paymentModalWrapper {
  min-width: 750px;
  max-width: 750px;
  background: linear-gradient(250.56deg, #0f1531 0%, #263175 99.24%);
  color: white;
  border-radius: 36px;
  padding: 46px 58px 32px 58px;

  .header {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
    border: none;
    padding: 0;
    margin-bottom: 32px;

  .closeIcon{
    align-self: start;
  }

    .logoAndText {
      display: flex;
      align-items: center;
      gap: 24px;
      justify-content: center;
      flex: 2;
      h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
      }

      button {
        margin: 0;
        justify-self: end;

        svg {
          path {
            fill: #0a53be;
          }
        }
      }
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .modalBody {
    padding: 0;
    form {
      display: flex;
      flex-direction: column;

      input {
        font-size: 1.125rem;
        font-weight: 400;
        padding: 10px 0 10px 32px;
        background: rgba(241, 241, 241, 0.08);
        border-radius: 8px;
        border: none;
        margin-top: 24px;
        margin-bottom: 4px;
        color: white;

        &::placeholder {
          color: #6672a2;
        }
      }

      span {
        color: #ff0033;
        margin-left: 8px;
      }

      button {
        align-self: end;
        width: fit-content;
        padding: 12px 52px;
        background: #5d9ef9;
        color: white;
        border-radius: 12px;
        font-size: 1.125rem;
        font-weight: 500;
        border: none;
        margin-top: 22px;

        &:hover {
          background: #4a84d4;
        }

        &:active {
          background: #3b6fb0;
        }
      }
    }
  }
}
