.container {
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #3047af;
    margin: 1em 0;
    padding: 0;
    opacity: 1;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 20px;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    p {
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .summary {
    span {
      font-size: 26px;
      color: #3e92d4;
      font-weight: 700;

      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    p {
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}
