@import '/src/sass/variables/variables';

.table {
  background-color: $brand-primary;
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    color: #fff;
  }
}
table {
  border-collapse: collapse;
  border-style: hidden;
}
table td {
  border: 1px solid grey;
}
