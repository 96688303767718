@import '/src/sass/variables/variables';

.breakMe {
  a {
    color: white;
  }
}
.active {
  a {
    border-bottom: 1px solid #fff;
    font-weight: bold;
    color: white;
  }
}

.hideButton {
  display: none;
}

.showButton {
  display: block;
}
.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .pagination {
    font-size: 16px;
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }
}
