.container {
  max-width: 1200px;
  min-height: 675px;
  height: fit-content;
  @media screen and (max-width: 1200px) {
    width: 80% !important;
    min-width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    min-width: 768px;
  }

  @media screen and (max-width: 767px) {
    width: 375px !important;
    min-width: 375px;
  }
}

.background {
  width: 100%;
  height: 675px;
  position: relative;
}

.background_img {
  height: 100%;
  width: 100%;
  min-height: 675px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox {
  span {
    color: white;
  }
}

.row_container_columns {
  width: 100%;
  min-height: 75%;
  .stb_left_column {
    width: 45%;
    height: calc(100% - 57px);
    margin-top: 57px;
    margin-left: 5%;
    min-width: 325px;
    @media screen and (max-width: 722px) {
      justify-content: center;
      margin-left: unset;
    }
  }
  .right_column {
    width: 45%;
    margin-top: 57px;
    margin-right: 5%;
    text-align: center;
    min-width: 325px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    @media screen and (max-width: 722px) {
      margin-right: unset;
    }
  }
  @media screen and (max-width: 722px) {
    justify-content: center;
  }
}

.row_container_buttons {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 25%;
  height: fit-content;
  .column_buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button {
      width: 60%;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}
