.container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  flex-direction: column;
  margin: 80px auto;

  @media screen and (max-width: 1200px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 870px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 560px) {
    padding: 0 24px;
  }

  .cards {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;

    @media screen and (max-width: 870px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .inactive {
    color: #eb110c;
    font-size: 14px;
    margin-top: 24px;
    p {
      margin-bottom: 0;
    }
  }

  .info {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 870px) {
      flex-direction: column;
      align-self: start;
    }

    h2 {
      margin: 0;
    }

    .cancel {
      background: none;
      color: white;
      border: none;
      padding-right: 16px;
    }

    .save {
      background: #3e92d4;
      border-radius: 4px;
      border: none;
      color: white;
      padding: 10px 30px;
    }
  }

  hr {
    margin: 24px 0;
    width: 100%;
  }

  .addNew {
    width: 100%;
    h2 {
      margin-top: 50px;
    }

    .cards {
      display: flex;
      justify-content: left;
    }
  }
}
