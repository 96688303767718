@import '/src/sass/variables/variables';

.formGroup {
  display: flex;
  flex-direction: column;
  input {
    width: 60%;
    padding: 5px;
    margin-bottom: 5px;
    border: none;
  }
  select {
    width: 60%;
    padding: 5px;
    margin-bottom: 5px;
    border: none;
  }
}
textarea {
  width: 60%;
  resize: none;
  padding: 5px;
  margin-bottom: 5px;
  border: none;
}
.submitButton {
  width: 60%;
  input {
    float: right;
    background: $brand-primary;
    margin: 8px 0;
    padding: 10px;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}
