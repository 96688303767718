.marker {
  &.neustart {
    background: url('https://cdn-g.bluemovie.com/old_images/BMO-Neustart-Label.png');
    background-size: cover;
  }

  &.vr {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-VR-Label.png');
    background-size: cover;
  }

  &.blue-movie-plus {
    background: url('https://cdn-g.bluemovie.com/old_images/bmo-svod-banner-left-bmo-plus-dark.png');
    background-size: cover;
  }

  background-size: contain;
  z-index: 10;
  position: absolute;
  top: 0;
  padding: 5px;
  width: 100px;
  height: 100px;
  @media screen and (max-width: 767px) {
    width: 75px;
    height: 75px;
  }
}

.movieWrapper {
  margin-bottom: 15px;

  .movieImage {
    position: relative;
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .movieText {
    text-overflow: ellipsis;

    a {
      color: #fff;
      display: block;
      text-transform: capitalize;
      font-weight: 300;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .promotionImage {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .promotionText {
    position: absolute;
    left: 5px;
    text-align: center;
    top: 23px;
    width: 100%;
    font-weight: bold;
    user-select: none;
  }
}
