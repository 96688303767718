.container {
  width: 288px;
  height: 200px;
  background: #0f132f;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 870px) {
    height: fit-content;
  }


  &.selected {
    border: 1.5px solid #3e92d4;
  }
  &.blocked {
    opacity: 0.5;
  }

  .mainContent {
    margin: 16px 16px 0 16px;

    @media screen and (max-width: 870px) {
      margin-bottom: 35px;
    }

    header {
      margin-bottom: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .imgAndStatus {
        display: flex;
        gap: 16px;
      }

      .img {
        max-height: 28px;

        &.paypalImg {
          height: 27px;
        }

        &.qboxImg {
          width: auto;
        }
      }

      .trashButton {
        background: none;
        border: none;
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    span {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    .status {
      width: fit-content;
      padding: 3px 12px;
      border-radius: 4px;
      font-size: 14px;
      height: fit-content;
    }
  }

  .btn {
    background: #3e92d4;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 8px 5px;
    margin: 0 8px 8px 8px;
    font-size: 12px;



    &.notSelected {
      background: #252f6d;
    }

    &.disabled {
      background: #757575;
      cursor: not-allowed;
    }
  }
}
