@import '/src/sass/variables/variables';

.paginationWrapper {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: $brand-primary;
  .active {
    a {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      color: white;
      font-size: 16px;
    }
  }
  .breakMe {
    a {
      color: white;
    }
  }

  li {
    margin: 0 5px 0 5px;

    a {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

  .pagination {
    font-size: 16px;
  }
}

.title {
}

.categoryWrapper {
  nav {
    padding: 0;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    nav {
      padding: 0;
    }
    i {
      margin-left: 10px;
    }
    .categoryButton {
      background: $brand-primary;
      padding: 13px 10px;
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      font-size: 14px;
      color: #fff;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      width: 100%;
    }
    .categoryBox {
      max-height: 50vh;
      overflow: auto;
      div {
        flex-direction: column;
      }
    }
  }
  .categoryBox {
    div {
      flex-direction: column;
      width: 100%;
    }
  }

  .categories {
    a {
      text-decoration: none;
      color: #fff;
    }

    ul {
      padding: 0;
      list-style: none;
      text-align: left;

      li {
        padding: 5px 0 5px 15px;
        background-color: $brand-tertiary;
        border-bottom: 0.5px solid $brand-secondary;
        &.active {
          background-color: #202f73;
          cursor: pointer;
        }
        &:hover{
          background-color: #202f73;
          cursor: pointer;
        }
      }

    }
  }
}

.sortWrapper {
  margin-bottom: 30px;
  z-index: 999;

  .sortOptions {
    display: block;
    width: 100%;
    background: white;
    color: $brand-secondary;
    border-radius: 0;
    padding: 4px;
    text-transform: uppercase;

    cursor: pointer;
    outline: none;

    select {
      color: $brand-secondary;
      width: 100%;
      border: none;
      font-size: 0.9em;
      font-weight: 600;
    }
  }
  .customSelect {
    border: none;
    border-radius: 0;
    outline: none;
    color: $brand-secondary;
    z-index: 333333333;
    //width: 100%;
    //height: 100%;
    //background: white;
  }
}

.mainWrapper {
  min-height: calc(100vh - 280px);
}
.mobilePagination {
  display: none;
  @media only screen and (max-device-width: 767px) {
    display: block;
  }
}
.topNavigation {
  display: none;
  @media only screen and (min-device-width: 768px) {
    display: block;
  }
}
.hideButton {
  display: none;
}

.showButton {
  display: block;
}
