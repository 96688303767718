@import '/src/sass/variables/variables';

.loginButton {
  width: 100px;
  padding: 11px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
}

.loginButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: all !important;
}
