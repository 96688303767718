.radioButton {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background: #253165;
  border: 1.01px solid #3047af;
  accent-color: #3047af;
}

input[type='radio'] {
  appearance: none;
  margin: 0 22px 0 0;
  background-color: #253165;
  font: inherit;
  color: currentColor;
  width: 25px;
  height: 25px;
  border: 1.01px solid #3e92d4;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-right: 5px;
  }
}

input[type='radio']::before {
  content: '';
  width: 65%;
  height: 65%;
  border-radius: 50%;
  background-color: transparent;
  transition: 120ms transform ease-in-out, 120ms background-color ease-in-out;
}

input[type='radio']:checked::before {
  background-color: #3e92d4;
  transform: scale(1);
}
