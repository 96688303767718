@import '/src/sass/variables/variables';

$border-radius: 4px;

.cardImage {
  max-height: 48px;
  max-width: 160px;
}

.pageHeader {
  cursor: pointer;
  background: #3047af;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: $border-radius;

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 50px;

    h3 {
      font-size: 14px;
    }
  }

  h3 {
    margin: 0;
    font-weight: 800;
  }

  button {
    padding: 10px;
    margin-left: 20px;
  }
}

.imageGroup {
  img {
    max-height: 32px;
    max-width: 112px;
  }
}

.card,
.accordion_button,
.accordion_item {
  cursor: pointer;
  background-color: #0f132f !important;
  margin-bottom: 5px;
  font-weight: 800;
  border: none;
  color: #fff !important;
  box-shadow: none !important;

  .cardBody {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 800;
    }
  }
}

.card {
  margin-bottom: 16px;
}

.accordion_item {
  margin: 0;
}
.accordion_button {
  height: 48px;
  padding: 12px 20px;
  margin: 0;
}
.accordion_button::after {
  display: none !important;
}

.table {
  color: white;
  border: 1px solid #dee2e6;
  --bs-table-bg: none;

  th,
  td {
    color: white;
    border: 1px solid #dee2e6;
  }

  th:last-child,
  td:last-child {
    border-right: 1px solid #dee2e6;
  }

  th {
    @media screen and (max-width: 400px) {
      font-size: 10px;
    }

    @media screen and (max-width: 310px) {
      font-size: 8px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    border: none;
    padding: 5px;
    text-transform: uppercase;
    background-color: #fff;
    color: #0f132f;
    font-size: 12px;
    width: 90%;
    margin: auto;

    @media screen and (max-width: 400px) {
      font-size: 10px;
    }
    @media screen and (max-width: 310px) {
      font-size: 8px;
    }
  }
}

.paymentButton {
  padding: 8px 64px;
  margin-left: 5px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: $brand-primary;
  font-weight: 600;
}

.paymentInput {
  margin-left: 5px;
  min-height: 36px;
  padding: 5px;
  border: none;
  color: $brand-secondary;
}

.paymentTypeSelect {
  width: 90%;
  @media screen and (max-width: 560px) {
    font-size: 14px;
  }
}

.typeImage {
  max-width: 170px;
  @media screen and (max-width: 560px) {
    width: 105px;
  }
  @media screen and (max-width: 400px) {
    width: 95px;
  }

  @media screen and (max-width: 370px) {
    width: 70px;
  }

  @media screen and (max-width: 300px) {
    width: 55px;
  }
}

.radioWrapper {
  color: #d7d7d7;
  cursor: pointer;
  display: flex;
  background: #0f132f;
  //margin-left: 10%;
  align-items: center;
  padding: 10px 15px;
  border-radius: $border-radius;
  height: 76px;
  margin-bottom: 10px;
  box-sizing: border-box;
  justify-content: space-between;

  p {
    margin: 0;

    @media screen and (max-width: 290px) {
      font-size: 12px;
    }
  }
}

.radioWrapper.selected {
  border: 3px solid #0075ff;
}

.typeWrapper {
  padding: 0 16px;
  cursor: pointer;
  height: 75px;
  background: #0f132f;
  border-radius: $border-radius;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }

  img {
    //max-width: 130px;
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      max-width: 100px !important;
    }
  }
}

.typeWrapper.selected {
  border: 3px solid #0075ff;
}

.totalWrapper {
  color: black;
  background: #e5e5e5;
  border-radius: $border-radius;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  padding: 27px 20px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
  span {
    font-weight: 800;
  }
}

.voucherWrapperMobile {
  color: white;
  flex-direction: column;
  background: #0f132f;
  border-radius: $border-radius;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 10px;
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  span {
    font-weight: 800;
  }

  input {
    border-radius: $border-radius;
    width: 60%;
    border: none;
    font-size: 1rem;
    padding: 12px 20px;
    background: lightgrey;
    height: 43px;
  }

  button {
    border-radius: $border-radius;
    background: #1a8827;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    border: none;
    width: 30%;
    height: 43px;
  }
  button:disabled {
    background: #72758c;
  }
}
.voucherWrapper {
  color: white;
  flex-direction: column;
  background: #0f132f;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  span {
    font-weight: 800;
  }

  input {
    border-radius: $border-radius;
    width: 60%;
    border: none;
    font-size: 1rem;
    padding: 12px 20px;
    background: white;
    height: 43px;
    color: #212529;
    font-weight: 600;
    &::placeholder {
      color: #212529;
      font-weight: 600;
    }
    &:focus {
      outline: 2px solid orange;
    }
  }

  button {
    border-radius: $border-radius;
    background: #1a8827;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    border: none;
    width: 30%;
    height: 43px;
  }
  button:disabled {
    background: #72758c;
  }
}

.submitButton {
  border-radius: 5px !important;
  padding: 10px 30px;
  margin-left: 5px;
  position: relative;
  width: fit-content;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: #1a8827;
  font-weight: 600;
  margin-top: 20px;

  .green {
  }
}

.submitButton:disabled {
  background-color: grey;
}

.sepa {
  height: 22px;
  max-width: unset;
}

.left {
  @media only screen and (min-width: 767px) {
    padding-right: 50px;
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    padding-right: 5px;
  }
}

.right {
  @media only screen and (min-width: 767px) {
    padding-left: 50px;
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    padding-left: 5px;
  }
}
.priceHolder {
  @media only screen and (min-width: 1200px) {
    font-weight: 600;
    text-align: center;
    font-size: 26px;
  }
  font-size: 25px;
  @media (max-width: 359px) {
    font-size: 18px;
  }

  @media (max-width: 319px) {
    font-size: 14px;
  }
}
//.typeImage {
//  width: 300px
//}

h6 {
  font-size: 20px;
  margin-bottom: 24px;
}

.primaryPayment {
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
}
